// eslint-disable-next-line import/no-unassigned-import
import 'vite/modulepreload-polyfill'
import { updateCartData } from '@js/api/cart/updateCartData.ts'
import { initToTopButton } from '@js/components/Buttons/btn--to-top.ts'
import { initCitySelect } from '@js/components/CitySelect/initCitySelect.ts'
import { initCookieWarning } from '@js/components/CookieWarning/initCookieWarning.ts'
import { fancyboxDynamicLoader } from '@js/components/Fancybox/fancyboxDynamicLoader.ts'
import { initBootstrapFormValidation } from '@js/components/Forms/bootstrapValidation.ts'
import { initTableFilter } from '@js/components/Forms/TableFilter/initTableFilter.ts'
import { initHeaderSearch } from '@js/components/HeaderSearch/initHeaderSearch.ts'
import { initMobileFooter } from '@js/components/initMobileFooter.ts'
import { initMobileHeaderPopups } from '@js/components/mobile-header-popup.ts'
import { initStaticDraggableModals } from '@js/components/modals/addModalDrag.ts'
import { initLoginModal } from '@js/components/modals/LoginModal.ts'
import { initNotifications } from '@js/components/Notifications/initNotifications.ts'
import { initOffcanvasNavbar } from '@js/components/OffcanvasNavbar.ts'
import { initCardNeighborsSaving } from '@js/components/product-card/cardNeighbors.ts'
import { initProductCardHelper } from '@js/components/product-card/product-card.ts'
import { initVidstack } from '@js/components/Vidstack/initVidstack.ts'
import { initBootstrapCollapseTextHandler } from '@js/includes/bootstrapCollapseTextHandler.ts'
import { debounce } from '@js/includes/functions.ts'
import { initPopovers } from '@js/includes/initPopovers.ts'
import { loadPolyfills } from '@js/includes/polyfills.ts'
import { initFixedHeader } from '@js/site-functions/fixedHeader.ts'
import { initForcedTextWidthHelper } from '@js/site-functions/forceTextWidth.ts'
import { saveLastVisitedCatalog } from '@js/site-functions/saveLastVisitedCatalog.ts'
import setScrollbarWidth from '@js/site-functions/setScrollbarWidth.ts'
import { initPausableVideo } from '@js/site-functions/video.ts'
import { initYandexMetrika } from '@js/site-functions/YandexMetrika.ts'
import { setHeaderFavouritesCount } from '@js/stores/favouritesStore.ts'
import { mobile } from '@js/stores/globalStores.ts'
import * as Sentry from '@sentry/svelte'

let environment = 'production'

if (/^(?:d|admin|t\d)\.santehnica\.ru$/u.test(document.location.host)) {
  environment = document.location.host
}

export const loadBase = async () => {
  // use it in sentry init
  Sentry.init({
    dsn: 'https://5a7c27526e5d1398cf2f41d24df8828e@sentry2.sanicom.ru/1',
    release: import.meta.env.__COMMIT_HASH__,
    environment,
    // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
    tracePropagationTargets: [
      'localhost',
      /^https:\/\/[a-zA-Z]+\.santehnica\.ru/u,
      /^https:\/\/img\.sanicom\.ru/u,
      /^https:\/\/santehnica-shop\.by/u,
      /^https:\/\/santehnica-shop\.kz/u,
      /^https:\/\/santehnica\.kg/u,
    ],
    integrations: [Sentry.browserTracingIntegration()],
    // Performance Monitoring
    tracesSampleRate: environment === 'staging' ? 1 : 0.1, // Capture 100% of the transactions, reduce in production!
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
    enabled: import.meta.env.PROD,
  })

  // Load polyfills
  await loadPolyfills()

  await import('@js/includes/importBootstrapComponents.ts')

  const updateMobileState = () => {
    const mobileQuery = window.matchMedia('screen and (max-width: 991px)')
    mobile.set(mobileQuery.matches)
  }

  window.addEventListener('resize', debounce(updateMobileState))

  initBootstrapFormValidation()
  initMobileFooter()
  setScrollbarWidth()
  setHeaderFavouritesCount()
  initFixedHeader()
  initHeaderSearch()
  initPausableVideo()
  initForcedTextWidthHelper()
  initProductCardHelper()
  initNotifications()
  initOffcanvasNavbar()
  initMobileHeaderPopups()
  initCitySelect()

  // eslint-disable-next-line promise/prefer-await-to-then
  import('./components/CatalogMenu/catalogMenu.ts').then((imp) => {
    imp.initCatalogMenu()
  })

  // Грузим только если есть слайдеры на странице
  if (document.querySelector<HTMLElement>('.swiper-wrap')) {
    // eslint-disable-next-line promise/prefer-await-to-then
    import('@js/components/Swiper/swiper.ts').then((imp) => {
      imp.initSwiper()
    })
  }

  // eslint-disable-next-line promise/prefer-await-to-then
  import('@js/components/modals/callback/initCallbackModal.ts').then((imp) => {
    imp.initCallbackModal()
  })

  // eslint-disable-next-line promise/prefer-await-to-then
  import('@js/components/modals/order-info/initOrderInfo.ts').then((imp) => {
    imp.initOrderInfo()
  })

  // noinspection JSIgnoredPromiseFromCall
  updateCartData()
  // eslint-disable-next-line promise/prefer-await-to-then
  import('@js/components/Cart/HeaderCart/initHeaderCart.ts').then((imp) => {
    // noinspection JSIgnoredPromiseFromCall
    imp.initHeaderCart()
  })
  initToTopButton()
  initPopovers()
  initYandexMetrika()
  initStaticDraggableModals()
  initLoginModal()
  initCookieWarning()
  initCardNeighborsSaving()
  saveLastVisitedCatalog()
  initVidstack()
  initBootstrapCollapseTextHandler()
  initTableFilter()

  // eslint-disable-next-line promise/prefer-await-to-then
  import('@js/components/recentProducts.ts').then((imp) => {
    // noinspection JSIgnoredPromiseFromCall
    imp.initRecentProducts()
  })

  if (document.querySelector('[data-fancybox]')) {
    // eslint-disable-next-line promise/prefer-await-to-then
    fancyboxDynamicLoader().then(({ Fancybox, defaultFancyboxSettings }) => {
      Fancybox.bind('[data-fancybox]', defaultFancyboxSettings)
    })
  }

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-expect-error
  import('instant.page')
}
