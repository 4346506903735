<script lang="ts">
  import { type HeaderSearchResult } from '@js/components/HeaderSearch/HeaderSearchTypes.ts'
  import Loader from '@js/components/Loader.svelte'
  import { sprites } from '@js/includes/_generatedVariables.ts'
  import { debounce } from '@js/includes/functions.ts'
  import { mobile } from '@js/stores/globalStores.ts'
  import { createEventDispatcher, onMount } from 'svelte'
  import wretch from 'wretch'
  // eslint-disable-next-line import/no-named-as-default
  import QueryStringAddon from 'wretch/addons/queryString'
  import { dedupe, retry, throttlingCache } from 'wretch/middlewares'

  export let loading = false
  export let ariaControls = ''
  export let show = false
  export let id = 'search-main'
  let localClass = ''
  export { localClass as class }
  export let inputValue = ''

  let searchWrap: HTMLElement
  let resultHTML = ''
  const localWretch = wretch('/ajax/search.php')
    .addon(QueryStringAddon)
    .middlewares([
      retry({
        maxAttempts: 3,
      }),
      dedupe(),
      throttlingCache({
        skip: (_url, options) => options.method !== 'GET',
      }),
    ])

  const dispatch = createEventDispatcher<{
    update: string
    error: null
    mount: HTMLElement | null
  }>()

  const onClick = () => {
    // На десктопе при пустом поле не надо ничего активировать, на мобиле надо.
    if (inputValue === '' && !$mobile) {
      return
    }

    show = true
  }

  const updateAllResultsLink = (htmlString: string) => {
    const temporaryDiv = document.createElement('div')
    temporaryDiv.insertAdjacentHTML('afterbegin', htmlString)
    const searchAllLink = temporaryDiv.querySelector<HTMLLinkElement>('.search-results-modal-result--show-all a')
    if (searchAllLink) {
      searchAllLink.href = '/search/?keyword=' + encodeURIComponent(inputValue)
    }

    return temporaryDiv.innerHTML
  }

  const clearData = () => {
    resultHTML = ''
    show = false
    loading = false
    dispatch('update', resultHTML)
  }

  const onInputChange = async () => {
    if (inputValue === '') {
      clearData()
      return
    }

    loading = true
    if (import.meta.env.DEV) {
      resultHTML = ''
      const imp = await import('@js/__development-api/search')
      resultHTML = (await imp.returnData()).data
    } else {
      const response = await localWretch
        .query({ keyword: inputValue })
        .get()
        .json<HeaderSearchResult>()
        .catch(() => {
          dispatch('error')
          return null
        })

      if (response) {
        resultHTML = response.data
      }
    }

    resultHTML = updateAllResultsLink(resultHTML)

    dispatch('update', resultHTML)

    loading = false
    show = true
  }

  const formSubmit = () => {
    if (inputValue) {
      window.location.href = '/search/?keyword=' + encodeURIComponent(inputValue)
    }
  }

  onMount(() => {
    dispatch('mount', searchWrap)

    return () => {
      dispatch('mount', null)
    }
  })
</script>

<form
  role="search"
  on:submit|preventDefault={formSubmit}
  class={localClass}
>
  <div
    bind:this={searchWrap}
    class="search-field"
    class:search-field--active={show}
    class:search-field--mobile={$mobile}
  >
    <input
      {id}
      name={id}
      type="search"
      bind:value={inputValue}
      on:focus={onClick}
      on:click={onClick}
      on:input={debounce(onInputChange, 10)}
      aria-controls={ariaControls}
      autocomplete="off"
      class="form-control form-control-lg"
      placeholder="Поиск товаров и категорий"
    />
    <label
      for={id}
      class="visually-hidden">Поиск товаров и категорий</label
    >
    {#if loading}
      <Loader class="search-field__loader" />
    {/if}
    <button
      type="reset"
      class="btn btn-transparent search-field__btn-reset fade"
      class:show={show ?? !loading}
      on:click={clearData}
    >
      <svg
        width="10"
        height="10"
        role="img"
      >
        <title>Сбросить поиск</title>
        <use xlink:href="{sprites.svgsprite2}#controls--close"></use>
      </svg>
    </button>
    <button
      class="search-field__btn-submit"
      type="submit"
      title="Искать"
    >
      <svg
        width="26"
        height="26"
        role="img"
      >
        <title>Искать</title>
        <use xlink:href="{sprites.svgsprite2}#icon_search_outline"></use>
      </svg>
    </button>
  </div>
</form>
