// Debounce
import { cartData } from '@js/stores/cartData.ts'
import { captureException } from '@sentry/svelte'
import { get } from 'svelte/store'

export const debounce = (inputFunction: Function, time: number = 100) => {
  let timer: number | undefined
  return function (event?: unknown) {
    if (timer) clearTimeout(timer)
    timer = setTimeout(inputFunction, time, event)
  }
}

// Множественная форма без полифилла
// function declOfNum(number, words) {
//     return words[(number % 100 > 4 && number % 100 < 20) ? 2 : [2, 0, 1, 1, 1, 2][(number % 10 < 5) ? Math.abs(number) % 10 : 5]];
// }

// Множественная форма: товар/товара/товаров
export const pluralize = (count: number, words: [string, string, string]) => {
  const selected = new Intl.PluralRules('ru-RU').select(count)

  switch (selected) {
    case 'few':
      return words[1]
    case 'many':
      return words[2]
    case 'one':
    default:
      return words[0]
  }
}

export const formatPrice = (
  number_: number | string,
  currencyCode: string | null | undefined = '',
  noSpace = false,
) => {
  const cartCurrencyCode = get(cartData)?.currency_code

  let currencyLocale = 'ru'
  let defaultCurrencyCode = 'RUB'
  switch (window.santehnica_data?.cityID) {
    case 8:
      // Для Беларуси используем ru-локаль, иначе валюта обозначается как Br
      // currencyLocale = 'ru-BY'
      defaultCurrencyCode = 'BYN'
      break
    case 7:
      currencyLocale = 'ru-KZ'
      defaultCurrencyCode = 'KZT'
      break
    case 196:
      currencyLocale = 'ru-KG'
      defaultCurrencyCode = 'KGS'
      break
    default:
      break
  }

  let localCurrencyCode = !currencyCode || currencyCode === '' ? cartCurrencyCode : currencyCode
  localCurrencyCode = !localCurrencyCode || localCurrencyCode === '' ? defaultCurrencyCode : localCurrencyCode

  const convertedNumber = typeof number_ === 'string' ? Number.parseFloat(number_.replace(' ', '')) : number_

  let result = ''

  const formatOptions: Intl.NumberFormatOptions = {
    style: 'currency',
    currency: localCurrencyCode,
    currencyDisplay: 'narrowSymbol',
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  }

  // Иногда с бэка может прилететь странное
  try {
    result = new Intl.NumberFormat(currencyLocale, formatOptions).format(convertedNumber)
  } catch (error) {
    captureException(error)

    result = new Intl.NumberFormat(currencyLocale, formatOptions).format(convertedNumber)
  }

  // Если noSpace, только у рубля убираем пробел
  return noSpace ? result.replace(/\s₽/u, '₽') : result
}

/**
 * Shuffles the elements of an array.
 * src: https://stackoverflow.com/questions/2450954/how-to-randomize-shuffle-a-javascript-array
 */
export const shuffleArray = <T>(array: T[]): T[] => {
  let currentIndex = array.length
  let randomIndex

  // While there remain elements to shuffle.
  while (currentIndex > 0) {
    // Pick a remaining element.
    randomIndex = Math.floor(Math.random() * currentIndex)
    currentIndex--

    // And swap it with the current element.
    ;[array[currentIndex], array[randomIndex]] = [array[randomIndex], array[currentIndex]]
  }

  return array
}

export const asyncSetTimeout = (function_: () => void, delay: number) =>
  new Promise<void>((resolve) => {
    setTimeout(() => {
      function_()
      resolve()
    }, delay)
  })

// https://stackoverflow.com/questions/175739/how-can-i-check-if-a-string-is-a-valid-number
export const isNumeric = (string_: string) => {
  return (
    !Number.isNaN(string_) && // use type coercion to parse the _entirety_ of the string (`parseFloat` alone does not do this)...
    !Number.isNaN(Number.parseFloat(string_))
  ) // ...and ensure strings of whitespace fail
}

// Based on https://stackoverflow.com/a/47614491/2508755
export const forceScriptExecution = (parent: HTMLElement | null | undefined) => {
  if (!parent) {
    return
  }

  const oldScriptElements = parent.querySelectorAll('script')
  for (const oldScriptElement of oldScriptElements) {
    const newScriptElement = document.createElement('script')

    for (const attribute of Array.from(oldScriptElement.attributes)) {
      newScriptElement.setAttribute(attribute.name, attribute.value)
    }

    const scriptText = document.createTextNode(oldScriptElement.innerHTML)
    newScriptElement.appendChild(scriptText)

    oldScriptElement.parentNode?.replaceChild(newScriptElement, oldScriptElement)
  }
}

/**
 * Preloads a function when specific events occur on the given button.
 * @param button - The button element to listen for events.
 * @param function_ - The function to preload.
 * @param [events] - Optional events configuration.
 */
export const sanPreload: (button: HTMLElement, function_: Function, events?: SanPreloadEvents) => void = (
  button,
  function_,
  events?,
) => {
  const localEvents =
    events ??
    ({
      click: true,
      mouseenter: true,
      focus: true,
    } satisfies SanPreloadEvents)

  const abortController = new AbortController()

  for (const event in localEvents) {
    if (Object.hasOwn(localEvents, event)) {
      button.addEventListener(
        event,
        () => {
          abortController.abort()
          function_()
        },
        {
          passive: true,
          once: true,
          signal: abortController.signal,
        },
      )
    }
  }
}

export const parseFormattedNumber = (number: string): number => {
  const result = Number.parseFloat(number.replaceAll(' ', '').replaceAll(',', '.'))
  return Number.isNaN(result) ? 0 : result
}

export type SanPreloadEvents = Partial<Record<keyof HTMLElementEventMap, boolean>>
